import React, { useState, useRef } from "react";
import { qrcodegen } from "./qrGenerator";

const QrCode = () => {
  const [name, setName] = useState("");
  const [upiId, setUpiId] = useState("");
  const [amount, setAmount] = useState("");
  const canvasRef = useRef(null);
  const canvas = canvasRef.current;
  const generateQrCode = () => {
    let str = `upi://pay?pa=${upiId}&pn=${name}&am=${amount}.00&cu=INR
    `;
    if (qrcodegen && canvas) {
      const { QrCode } = qrcodegen;
      try {
        const qr = QrCode.encodeText(str, QrCode.Ecc.HIGH);
        const fixedSize = 200;
        const size = qr.size;
        const context = canvas.getContext("2d"); // Ensure this works
        const scale = fixedSize / size;

        canvas.width = fixedSize;
        canvas.height = fixedSize;

        context.clearRect(0, 0, canvas.width, canvas.height);

        for (let y = 0; y < size; y++) {
          for (let x = 0; x < size; x++) {
            context.fillStyle = qr.getModule(x, y) ? "#080808" : "#FFFFFF";
            context.fillRect(x * scale, y * scale, scale, scale);
          }
        }
      } catch (e) {
        console.error("Failed to generate QR code:", e);
      }
    }
  };
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mt-[50px] text-3xl">QR CODE GENERATOR</div>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter your name"
        className="border-[1px] border-black text-black w-[200px] rounded-[4px] mt-[20px] pl-[5px]"
      />

      <input
        value={upiId}
        onChange={(e) => setUpiId(e.target.value)}
        placeholder="Enter your upi Id"
        className="border-[1px] border-black text-black w-[200px] rounded-[4px] mt-[20px] pl-[5px]"
      />

      <input
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter your amount"
        className="border-[1px] border-black text-black w-[200px] rounded-[4px] mt-[20px] pl-[5px]"
      />

      <button
        onClick={generateQrCode}
        className="mt-[50px] text-[18px] bg-lime-500 px-[20px] py-[5px] rounded-[20px]"
      >
        Generate Qr Code
      </button>

      <canvas
        ref={canvasRef}
        width={200}
        height={200}
        style={{ border: "1px solid black ", marginTop: 20 }}
      />
    </div>
  );
};

export default QrCode;
