import QrCode from "./pages/qrCode";

function App() {
  return (
    <div className="">
      <QrCode />
    </div>
  );
}

export default App;
